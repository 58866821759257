import { baseApi } from '@services/api'

import { IProfile } from './profile.model'

export const profileApi = baseApi.injectEndpoints({
  endpoints(builder) {
    return {
      updateProfile: builder.mutation<
        IProfile.Update.Response,
        IProfile.Update.Request
      >({
        query: data => {
          return {
            url: 'accounts/doctor/profile',
            method: 'POST',
            data,
          }
        },
      }),
      updateImage: builder.mutation<
        IProfile.UpdateImage.Response,
        IProfile.UpdateImage.Request
      >({
        query: data => {
          return {
            url: 'accounts/doctor/image',
            method: 'POST',
            data,
          }
        },
      }),
    }
  },
})

export const { useUpdateProfileMutation, useUpdateImageMutation } = profileApi
