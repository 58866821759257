import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IHealthPlan } from '@model/health-info/health-info'
import { RootState } from '@store/store'

import { profileApi } from './profile.api'
import { IProfile } from './profile.model'
import { sessionApi } from '../session/session.api'

const initialState = {} as IProfile.Slice.State

export const profileSlice = createSlice({
  name: 'profileSlice',
  initialState,
  reducers: {
    addHealthPlan(state, action: PayloadAction<IHealthPlan>) {
      if (
        !state.doctor.healthPlans.some(plan => plan._id === action.payload._id)
      ) {
        state.doctor.healthPlans.push(action.payload)
      }
    },
    deleteHealthPlan(state, action: PayloadAction<IHealthPlan>) {
      state.doctor.healthPlans = state.doctor.healthPlans.filter(
        plan => plan._id !== action.payload._id,
      )
    },
    addSubspeciality(state, action: PayloadAction<string>) {
      state.doctor.subspecialities.push(action.payload)
    },
    deleteSubspeciality(state, action: PayloadAction<string>) {
      state.doctor.subspecialities = state.doctor.subspecialities.filter(
        subspeciality => subspeciality !== action.payload,
      )
    },
    addEducation(state, action: PayloadAction<string>) {
      state.doctor.education.push(action.payload)
    },
    deleteEducation(state, action: PayloadAction<string>) {
      state.doctor.education = state.doctor.education.filter(
        education => education !== action.payload,
      )
    },
    addExperience(state, action: PayloadAction<string>) {
      state.doctor.experience.push(action.payload)
    },
    deleteExperience(state, action: PayloadAction<string>) {
      state.doctor.experience = state.doctor.experience.filter(
        experience => experience !== action.payload,
      )
    },
    updateProfileDoctor(
      state,
      { payload }: IProfile.Slice.UpdateDoctor.Payload,
    ) {
      state.doctor = {
        ...state.doctor,
        ...payload,
      }
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      sessionApi.endpoints.session.matchFulfilled,
      (state, { payload }) => {
        state.doctor = payload.doctor
      },
    ),
      builder.addMatcher(
        profileApi.endpoints.updateProfile.matchFulfilled,
        (state, { payload }) => {
          state.doctor = {
            ...state.doctor,
            ...payload.doctor,
          }
        },
      )
  },
})

export const {
  addHealthPlan,
  deleteHealthPlan,
  addSubspeciality,
  deleteSubspeciality,
  addEducation,
  deleteEducation,
  addExperience,
  deleteExperience,
  updateProfileDoctor,
} = profileSlice.actions

export const doctorProfileSelector = (state: RootState) =>
  state.profileSlice.doctor
