import {
  BrowserRouter,
  Navigate,
  Route,
  Routes as Switch,
} from 'react-router-dom'

import { doctorSelector } from '@slices/session/session.slice'
import { useAppSelector } from '@store/store'

import { PrivateRoute } from './private-route'
import { Pages } from './routes.model'

export const Routes = () => {
  const doctor = useAppSelector(doctorSelector)

  return (
    <BrowserRouter>
      <Switch>
        {doctor && <Route path="/" element={<Navigate to="/home" />} />}
        {Pages.map(({ isPrivate, path, Component, title }, idx) =>
          !isPrivate ? (
            <Route key={idx} path={path} element={<Component />} />
          ) : (
            <Route
              key={idx}
              path={path}
              element={
                <PrivateRoute
                  isLogged={Boolean(doctor)}
                  component={Component}
                  title={title}
                />
              }
            />
          ),
        )}
      </Switch>
    </BrowserRouter>
  )
}
