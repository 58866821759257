import { IDoctor, IWorkWindowMapKey } from '@model/doctor/doctor'

export const formatDoctorWorkWindow = (
  workWindowGroup: IDoctor['workWindowGroup'],
) => {
  const weekday = String(
    new Date().getUTCDay(),
  ) as typeof IWorkWindowMapKey[number]

  const workday = workWindowGroup.find(item => item.days.includes(weekday))

  return workday ? `${workday.startAt}-${workday.endAt}` : null
}
