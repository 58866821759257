import { IAppointment } from '@model/appointment/appointment'

import { formatDate, formatTime } from '../date/date'
import { translateStatus } from '../status/status'

export const formatAppointmentList = (appointments: IAppointment[]) => {
  return appointments.map(formatAppointment)
}

export const formatAppointment = (appointment: IAppointment) => ({
  ...appointment,
  formattedDate: formatDate(new Date(appointment.start), false),
  formattedTime: formatTime(appointment.start),
  translatedStatus: translateStatus(appointment.statuses[0].status),
  status: appointment.statuses[0].status,
})
