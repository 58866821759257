import { CSSProperties } from '@mui/styled-engine'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    background: theme.palette.common.white,
    flexDirection: 'column',
    width: '95%',
    minHeight: '100vh',
    padding: '5px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentContainer: {
    padding: '5px',

    minHeight: 'calc(100vh - 3.5rem)',
    [theme.breakpoints.up('sm')]: {
      minHeight: 'calc(100vh - 4.0625rem)',
    },
    display: 'flex',
    background: theme.palette.common.white,
  },
  sidebarContainer: {
    backgroundColor: theme.palette.common.white,
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
      width: theme.spacing(30),
    },
  },
  profileRoute: { marginBottom: 64 },
  drawerPaper: {
    backgroundColor: '#1172d2',
    borderRadius: 35,
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: theme.spacing(3.5),
    marginBottom: 2,
    border: 'none',
    [theme.breakpoints.up('lg')]: {
      top: '1.5rem',
      width: theme.spacing(21),
      padding: 5,
      height: '95%',
    },
  },
  logo: {
    margin: theme.spacing(1),
    height: 'auto',
    maxWidth: '7.5rem',
  },
  link: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'pointer',
  },
  linkDown: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderBottom: '2px',
  },
  sidebarItem: {
    fontWeight: 400,
    color: theme.palette.common.white,
    display: 'flex',
    marginLeft: theme.spacing(1),
  },
  sidebarText: {
    fontSize: '13px',
  },
  alignItems: {
    display: 'flex',
    alignItems: 'center',
  },
  listContainer: {
    overflowX: 'hidden',
    border: '5px',
    borderColor: theme.palette.common.white,
  },
  listItem: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(0.1),

    '&:hover': {
      color: theme.palette.primary.main,
      opacity: 0.5,
      '& .MuiListItemIcon-root': {
        color: theme.palette.primary.main,
      },
      '& .MuiListItemText-root': {
        color: theme.palette.primary.main,
        '& .MuiTypography-body1': { color: theme.palette.primary.main },
      },
    },
  },
  listItemSelected: {
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      color: theme.palette.common.white,
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
      borderLeft: `4px solid ${theme.palette.common.white}`,
    },
  },
  main: {
    flexGrow: 1,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '95%',
    width: 'calc(100vw - 13.5rem)',
  },
  alignDashboard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  divider: {
    marginTop: theme.spacing(4),
    border: `1.5px solid ${theme.palette.common.white}`,
  },
  doctorAvatar: {
    ...(theme.typography.caption as CSSProperties),
    color: theme.palette.common.white[500],
    fontWeight: theme.typography?.fontWeightBold,
    backgroundColor: theme.palette.grey[600],
    cursor: 'pointer',
    borderColor: theme.palette.common.white,
    border: theme.spacing(0),
    height: '2.35rem',
    width: '2.4rem',
    borderRadius: '5px',
    display: 'block',
    objectFit: 'cover',
  },
  doctorBackground: {
    backgroundColor: theme.palette.common.white,
    height: '2.5rem',
    width: '2.5rem',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.spacing(0.5),
  },
  doctorBase: {
    marginTop: theme.spacing(3.5625),
    display: 'flex',
    alignItems: 'center',
  },
  doctorName: {
    fontWeight: theme.typography?.fontWeightBold,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(1),
    fontSize: 13,
  },
  dashboardContainer: {
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  pageHeader: {
    display: 'flex',
    background: theme.palette.common.white,
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  pageTitle: {
    fontWeight: 500,
    fontSize: '21.84px',
    lineHeight: '27px',
    color: theme.palette.additionalColors.title,
  },
  backButton: {
    fontSize: '0.8em',
    cursor: 'pointer',
    marginRight: theme.spacing(1),
  },
}))
