export const COMMON = {
  POLLING_INTERVAL_IN_SECONDS:
    Number(process.env.REACT_APP_APPOINTMENTS_POLLING_INTERVAL) * 1000,
  ENV: process.env.REACT_APP_ENV,
  SEARCH_PATIENTS_DEFAULT_LIMIT: Number(
    process.env.REACT_APP_SEARCH_PATIENTS_DEFAULT_LIMIT,
  ),
  SEARCH_PATIENT_DEBOUNCE: Number(
    process.env.REACT_APP_SEARCH_PATIENT_DEBOUNCE,
  ),
  CONTACT_EMAIL: process.env.REACT_APP_CONTACT_EMAIL,
  CONTACT_PHONE: process.env.REACT_APP_CONTACT_PHONE,
  MIN_SEARCH_CHARACTER: 3,
}
