import { Suspense } from 'react'

import { RouteProps as ReactDOMRouteProps, Navigate } from 'react-router-dom'

import { Dashboard } from '@components/Dashboard/dashboard'

type RouteProps = ReactDOMRouteProps & {
  component: React.ElementType
  isLogged: boolean
  title: string
}

export const PrivateRoute: React.FC<RouteProps> = ({
  component: Component,
  isLogged,
  title,
  ...rest
}) => {
  return isLogged ? (
    <Dashboard title={title}>
      <Suspense fallback={<></>}>
        <Component {...rest} />
      </Suspense>
    </Dashboard>
  ) : (
    <Navigate
      to={{
        pathname: '/',
      }}
    />
  )
}
