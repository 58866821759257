import React from 'react'

import { createRoot } from 'react-dom/client'

import './index.css'
import 'simplebar/dist/simplebar.min.css'
import { App } from './app'

const root = createRoot(document.getElementById('root') as Element)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
