import { baseApi } from '@services/api'
import { formatAppointmentList } from '@utils/format-appointments/format-appointments'

import { IPatient } from './patient.model'

export const patientApi = baseApi.injectEndpoints({
  endpoints(builder) {
    return {
      getPatientDetails: builder.query<
        IPatient.Get.Response,
        IPatient.Get.Request
      >({
        query: ({ patientId }) => {
          return {
            url: `accounts/patient/${patientId}/details`,
            method: 'GET',
          }
        },
        transformResponse: (response: IPatient.Get.Response) => {
          return {
            ...response,
            appointments: formatAppointmentList(response.appointments),
          }
        },
      }),
    }
  },
})

export const { useGetPatientDetailsQuery } = patientApi
