import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { HTTP } from '@config/http'

const { API_URL } = HTTP

export const baseQuery = (token?: string) =>
  fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: headers => {
      headers.set('Authorization', `Bearer ${token}`)
      return headers
    },
  })
