import { IAppointment } from '@model/appointment/appointment'

export const formatAppointmentsToCalendar = (
  appointments: (IAppointment & { count?: number; status?: string })[],
  period: 'month' | 'day',
) => {
  if (appointments.length === 0) return []

  return appointments.map(({ patient, statuses, status, count, ...rest }) => ({
    ...rest,
    title:
      period === 'day'
        ? patient.name
        : `${count} consulta${count && count > 1 ? 's' : ''}`,
    status: period === 'day' ? statuses[0].status : status,
  }))
}
