import { createSlice } from '@reduxjs/toolkit'

import { RootState } from '@store/store'

import { patientApi } from './patient.api'
import { IPatient } from './patient.model'

const initialState = {
  patient: {},
  currentAppointment: {},
} as IPatient.Slice.State

export const patientSlice = createSlice({
  name: 'patientSlice',
  initialState,
  reducers: {
    setPatient: (state, { payload }: IPatient.Slice.SetPatient.Payload) => {
      state.patient = payload
    },
    setCurrentAppointment: (
      state,
      { payload }: IPatient.Slice.SetCurrentAppointment.Payload,
    ) => {
      state.currentAppointment = payload
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      patientApi.endpoints.getPatientDetails.matchFulfilled,
      (state, { payload }) => {
        state.patient = payload.patient
      },
    )
  },
})

export const { setPatient, setCurrentAppointment } = patientSlice.actions

export const patientSelector = (state: RootState) => state.patientSlice.patient
export const currentPatientAppointmentSelector = (state: RootState) =>
  state.patientSlice.currentAppointment
