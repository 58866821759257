import { createSlice } from '@reduxjs/toolkit'

import { RootState } from '@store/store'

import { sessionApi } from './session.api'
import { ISession } from './session.model'

const initialState = {} as ISession.Slice.State

export const sessionSlice = createSlice({
  name: 'sessionSlice',
  initialState,
  reducers: {
    updateSession(state, { payload }: ISession.Slice.UpdateSession.Payload) {
      state.token = payload.token
      state.refreshToken = payload.refreshToken
    },
    updateDoctor(state, { payload }: ISession.Slice.UpdateDoctor.Payload) {
      state.doctor = {
        ...state.doctor,
        ...payload,
      }
    },
    signOut() {
      return initialState
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      sessionApi.endpoints.session.matchFulfilled,
      (state, { payload }) => {
        state.token = payload.token
        state.refreshToken = payload.refreshToken
        state.doctor = payload.doctor
      },
    )
  },
})

export const { updateSession, updateDoctor, signOut } = sessionSlice.actions

export const doctorSelector = (state: RootState) => state.sessionSlice.doctor
