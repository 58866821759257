import { ITranslateStatusResponse } from '@utils/status/status'

import { IPatient } from '../patient/patient'

export enum Status {
  Scheduled = 'Scheduled',
  Confirmed = 'Confirmed',
  Checked_In = 'Checked_In',
  In_Progress = 'In_Progress',
  Finished = 'Finished',
  Canceled = 'Canceled',
}

export const PAYMENT_METHOD_OPTIONS = [
  { label: 'Dinheiro/cartão', value: 'money/card' },
  { label: 'Plano', value: 'plan' },
]

export type IPaymentMethod = 'plan' | 'money/card'

export const APPOINTMENT_DURATION_OPTIONS = ['15', '30', '45', '60', '115']

export enum Intensity {
  Intense = 'Intense',
  Moderate = 'Moderate',
  Low = 'Low',
}

export enum IntensityName {
  Low = 'Fraco',
  Moderate = 'Médio',
  Intense = 'Forte',
}

export enum ExamType {
  Laboratory = 'Laboratory',
  Image = 'Image',
}

export enum ExamTypeName {
  Laboratory = 'Laboratorial',
  Image = 'Imagem',
}

export interface IAnamnesis {
  complaint: string
  intensity: string
}

export interface IPhysicalAnalysis {
  index: string
  value: string
}

export interface IClinicalAnalysis {
  diagnosis: string
  description: string
  visible: boolean
  _id?: string
}

export type IRecommendations = string[]

export interface IPrescriptionItem {
  medicine: string
  duration: string
  dosage: string
  time: string
  _id?: string
}

export interface IPrescription {
  _id?: string
  prescriptionItems: IPrescriptionItem[]
}

export interface IExamItem {
  type: string
  solicitations: string[]
}

export interface IExam {
  examItems: IExamItem[]
}

export interface IMedicalCertificate {
  period: string
  reason: string
  diagnosis?: string
  description?: string
}

export interface IAppointment {
  start: string
  end: string
  statuses: { status: keyof typeof Status }[]
  _id: string
  patient: IPatient
  anamnesis?: IAnamnesis[]
  physicalAnalysis?: IPhysicalAnalysis[]
  clinicalAnalysis?: IClinicalAnalysis
  recommendations?: IRecommendations
  prescriptions?: IPrescription[]
  exams?: IExam[]
  medicalCertificate?: IMedicalCertificate
  paymentMethod: IPaymentMethod
}

export interface IFormattedAppointment extends IAppointment {
  status: keyof typeof Status
  translatedStatus: ITranslateStatusResponse
  formattedDate: string
  formattedTime: string
}
